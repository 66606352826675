/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import "./node_modules/postcss-simple-vars";
import "./node_modules/postcss-nested";
import "./node_modules/postcss-responsive-type";
import "./node_modules/postcss-calc";
import "./node_modules/postcss-color-function";
import "./node_modules/modern-css-reset";
import "./src/styles/variables.css"
import "./src/styles/global.css"
import "./src/styles/fonts.css"
import "./src/styles/typography.css"
import "./src/components/hamburger.css"